.col-form {
  margin-bottom: 15px;
}
.normal-form label {
  font-weight: 650;
}

.soft-labels label{
  font-weight: 500;
}
.form-container {
    max-width: 800px; /* Máximo ancho */
    margin: auto; /* Centrar horizontalmente */
}