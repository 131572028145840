
@import url('./styles/Buttons.scss');
@import url('./styles/Header.scss');
@import url('./styles/Tables.scss');
@import url('./styles/Error.scss');
@import url('./styles/Images.scss');
@import url('./styles/Forms.scss');
@import url('./styles/Containers.scss');
.small-text th,
.small-text td {
    font-size: 14px;
}

@media print {
    .small-text th,
    .small-text td {
        font-size: 6px;
    }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.custom-modal1 .modal-dialog {
  width: 90vw !important; /* 90% del ancho del viewport */
  max-width: 3000px; /* Puedes definir un máximo si quieres limitar el tamaño */
  margin: 30px auto; /* Centrar el modal */
}
.footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
}

.main-content {
  align-items: center;
  background-color: #f1f1f1;
  height: 90vh;
  min-height: 750px;
}

.main-public-content { 
  flex: 1;
}

.list-container {
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  margin: 30px auto 0px auto ;
  height: 91%;
  border: 1px solid #c0c0c0;
  min-height: 650px;
  
}

.list0 {
  max-width: 1000px;
}

.list1 {
  max-width: 1500px;
}
.list2 {
  max-width: 500px;
}

.list3 {
  max-width: 700px;
}

.list4 {
  max-width: 800px;
}
.list5 {
  max-width: 1700px;
}

.list6 {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ocupa todo el espacio disponible */
}

.list-container-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Permite que los elementos se reubiquen en pantallas pequeñas */
  margin-bottom: 20px;
  background-color: rgb(255, 255, 255);
  gap: 10px; /* Espaciado entre los botones en pantallas pequeñas */
}

.left-buttons, .right-buttons {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos en estos contenedores también se reubiquen si es necesario */
  align-items: center; /* Alinea los elementos verticalmente */
}

.left-buttons {
  justify-content: flex-start;
}

.right-buttons {
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .list-container-header {
    flex-direction: column; /* Cambia a disposición en columna en pantallas pequeñas */
    align-items: flex-start;
  }

  .left-buttons, .right-buttons {
    width: 100%; /* Deja que los contenedores se ajusten al ancho de su contenido */
  }
  
}

.list-content {
  width: 100%;
  height: 90%;
}

.table-container {
  overflow-y: scroll;
  height: 80%;
  min-height: 250px;
}

.list-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.list-controls label{
  font-size: 0.9rem;
}

.sort-controls {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.mr-3 {
  margin-right: 1rem; /* Margen derecho de 1 rem */
}

.table-title {
  text-transform: uppercase; 
  font-size: 1.5rem;
}

.small-font {
  font-size: 12px; /* Adjust the size as needed */
}
.custom-modal-width .modal-dialog {
  max-width: 900px; /* Adjust to make the modal wider */
}

.table-responsive {
  max-height: 400px; /* Scroll for the detail table */
  overflow-y: auto;
}

.number-align-right {
  text-align: right;
}

.mr-3 {
  margin-right: 30px;
}